const initialState = {
    loading: false,
    authError: false 
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'loading':
            return {
                ...state,
                loading: action.payload
            }
            case 'authError':
                return {
                    ...state,
                    authError: action.payload
                }
        default:
            return { ...state }

    }

}

export default reducer;