const shopify_endpoints: any = {
    products: 'Products',
    variants: 'Variants',
    collections: 'Collections',
    collects: 'Collects',
    smart_collections: 'Smart Collections',
    custom_collections: 'Custom Collections',
    customers: 'Customers',
    orders: 'Orders',
    inventory_levels: 'Inventory Levels',
    locations: 'Locations',
    inventory_items: 'Inventory Items',
    metafields: 'Metafields',
    product_key_sync: 'Product Key Sync'
};

const namespaces: any = {
    smart_collections: 'PSCM',
    custom_collections: 'PCCM',
    products: 'PPM',
    variants: 'PPM'
}
export default {
    limit: 10,
    support_url: 'https://partners.alliance-ecommerce.in/support',
    appCode: {
        socialLoginHype: 'hypesocialloginplus',
        socialLogin: 'socialloginplus',
        timer: 'timer',
        datafeed: 'datafeed',
        metafields: 'metafields'
    },
    queryParams: function (location: any) {
        return new URLSearchParams(location.search);
    },
    shopify_endpoints: shopify_endpoints,
    namespaces: namespaces,
    website: 'https://alliance-ecommerce.in'
}